/* reset css */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, hr {
  margin: 0;
  padding: 0;
  border: 0;
}
i, cite, em, var, dfn, address {
  font-style: normal;
}
b, h1, h2, h3, h4, h5, h6, strong, th {
  font-weight: normal;
}

article, aside, figcaption, figure, footer, menu, section {
  display: block;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}
table {
  table-layout: fixed; 
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  vertical-align: bottom; 
}
img, object, video, iframe {
  max-width: 100%;
}
ol, ul {
	list-style: none;
}

/* a {
  text-decoration: none;
  color: black;
} */

*, ::before, ::after {
  position: relative; 
  box-sizing: border-box; 
  background-clip: padding-box; 
  hyphens: auto; 
}
[hidden] {
  display: none !important;
}
:disabled, [disabled], [aria-disabled="true"] {
  cursor: not-allowed; 
}

@media (prefers-reduced-motion: reduce) {
  *:not(.safe-animation),
  *:not(.safe-animation)::before,
  *:not(.safe-animation)::after {
    animation-duration: 0.01s !important;
    animation-delay: 0s !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
    scroll-behavior: auto !important;
  }
}