/* Cards container styles */
.group_card_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

/* Cards styles */
.group_card_container > .card {
  width: 100%;
  max-width: 329px;
  min-height: 240px;
  padding: 18px 8px;
  margin-bottom: 6px;
}

/* Card's header styles */
.group_card_container .card_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

/* .group_card_container .card_header > .card_header_logo {
  width: 68px;
  height: 68px;
  display: flex;
  background: darkgray;
  border-radius: 50%;
  margin-right: 6px;
}

.group_card_container .card_header > .card_header_logo p {
  margin: auto;
} */

.group_card_container .card_header > .card_header_logo {
  width: 68px;
  height: 68px;
  margin-right: 6px;
  border-radius: 50%;
}

.group_card_container .card_header > .card_header_logo img {
  border-radius: 50%;
  width: 68px;
  height: 68px;
  object-fit: cover;
} 


.group_card_container .card_header h3 {
  font-family: "century-old-style-std, serif";
  font-weight: 400;
  font-size: 16px;
}

.group_card_container .card_header > .card_header_creds {
  width: 215px;
}

.group_card_container .card_header p {
  font-weight: 700;
}

/* Card's contacts styles */
.group_card_container .card_contacts {
  margin-left: 74px;
  margin-bottom: 17px;
  width: 200px;
}

/* Card's button styles */
.group_card_container .card_btn {
  display: block;
  width: 230px;
  background: #1c3954;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  margin: auto;
  cursor: pointer;
}

.group_card_container .card_btn:hover {
  background: #435D74;
}

.group_card_container .card_btn > a {
  color: #fff;
  text-decoration: none;
}

/* small devices styles */

@media only screen and (max-width: 280px) {
  .group_card_container .card_header {
    flex-direction: column;
  }

  .group_card_container .card_contacts {
    margin-left: 0px !important;
  }

  .group_card_container .card_btn {
    width: 150px;
  }
}

@media only screen and (max-width: 410px) {
  .group_card_container .card_header > .card_header_creds {
    width: 150px;
  }

  .group_card_container .card_contacts {
    margin-left: 74px;
    margin-bottom: 17px;
    width: 120px;
  }
}
@media only screen and (max-width: 767px) {
  .group_card_container > .card {
    max-width: 100%;
  }
}

@media print {
  .group_card_container::after {
    content: "";
    clear: both;
    display: table;
  }
  .group_card_container {
    display: block;
  }
  .group_card_container > .card {
    float: left;
    margin-right: 3px;
    margin-bottom: 3px;
  }

  /* .group_card_container .card:nth-child(even),
  .group_card_container .card:nth-child(n + 8):nth-child(even) {
    margin-right: 0;
  } */


  .group_card_container > .page-break+.card,
  .group_card_container > .page-break+.card+.card {
    margin-top: 2em;
  }
}

