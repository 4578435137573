.top_section {
  margin-bottom: 30px;
}

.top_section > .top_section_name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.top_section h1 {
  font-family: "century-old-style-std, serif";
  font-size: 30px;
  font-weight: 400;
  margin-right: 12px;
}

.top_section img {
  width: 20.73px;
  height: 28px;
  align-self: start;
  margin-right: 8px;
}

.top_section hr {
  border: 0;
  height: 1px;
  background: #f77c54;
  align-self: start;
  margin-top: 26px;
  flex-grow: 2;
}

.top_section > .top_section_description {
  font-size: 16px;
  font-weight: 400;
}
