header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 26px;
}

header > img {
  width: 169px;
  height: 29px;
}

header > .header_date_and_print {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

header  p {
  font-style: italic;
  font-weight: 300;
  margin-right: 12px;
}

header button {
  border: none;
  color: #fff;
  font-weight: 400;
  padding: 7px 20px; 
  background: #1C3954;
  cursor: pointer;
}


header button:hover {
  background: #435D74;
}