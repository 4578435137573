/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* Variables */
:root {
  --fontSize: 14px;
  --fontFamily: "sofia-pro", sans-serif;
  --fontWeight: 400;
  --color: #1c3954;
}

/* General styles */

body {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--fontSize);
  font-family: var(--fontFamily);
  color: var(--color);
}


a {
  font-family: var(--fontFamily);
  color: var(--color);
}


.wrapper {
  margin: auto;
  padding: 25px 52px;
  max-width: 768px;

}


@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    margin-top: 10rem;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
    page-break-inside: avoid;
  }
  .group{
    margin-top: 2rem;
  }
}

@page {
  size: auto;
  margin: 0mm;
}


















